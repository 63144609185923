import React from 'react'
import Link from 'gatsby-link'
import TopNav from './topNav'

import logo from '../assets/vplogo.svg'

const Header = () => (
  <header>
    <div className="contents">
      <div className="logo">
        <Link to="/">
          <img alt="" src={logo} />
        </Link>
      </div>
      <h2>
        We Make Data Science Visible
      </h2>
      <TopNav />
    </div>
  </header>
)

export default Header
