import React from 'react'

import topgraphics1svg from '../assets/topgraphics-1.svg'
import topgraphics2svg from '../assets/topgraphics-2.svg'
import topgraphics3svg from '../assets/topgraphics-3.svg'

import topgraphics1png from '../assets/topgraphics-1.png'
import topgraphics2png from '../assets/topgraphics-2.png'
import topgraphics3png from '../assets/topgraphics-3.png'

class TopGraphics extends React.Component {
  constructor () {
    super()
    this.state = {
      svg: (
        typeof window !== 'undefined' &&
        window.navigator.userAgent.indexOf('Trident/') <= 0
      )
    }
  }

  render () {
    if (this.state === null || this.state.svg) {
      return (
        <div className='topgraphics'>
          <img alt="" src={topgraphics1svg} style={{ 'height': '254px' }} />
          <img alt="" src={topgraphics2svg} style={{ 'height': '367px' }} />
          <img alt="" src={topgraphics3svg} style={{ 'height': '242px' }} />
        </div>
      )
    } else {
      return (
        <div className='topgraphics'>
          <img alt="" src={topgraphics1png} style={{ 'height': '254px' }} />
          <img alt="" src={topgraphics2png} style={{ 'height': '367px' }} />
          <img alt="" src={topgraphics3png} style={{ 'height': '242px' }} />
        </div>
      )
    }
  }
}

export default TopGraphics
