import React from 'react'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'
import TopGraphics from '../components/topGraphics'
import Header from '../components/header'
import Footer from '../components/footer'

import favicon from '../assets/favicon.png'
import '../layouts/index.scss'

const firebase = require("firebase/app")
require("firebase/functions")

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

class Layout extends React.Component {
  constructor () {
    super()
    if (firebase.apps && firebase.apps.length === 0) {
      firebase.initializeApp({
        apiKey: "AIzaSyB-bXSR20l20xdjn4v5XrZN1JH8uI9VImg",
        authDomain: "consulting-site-a6dbe.firebaseapp.com",
        databaseURL: "https://consulting-site-a6dbe.firebaseio.com",
        projectId: "consulting-site-a6dbe",
        storageBucket: "",
        messagingSenderId: "399620839777"
      })
    }
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        this.checkScrollPosition()
      })
      this.checkScrollPosition()
    }
  }

  checkScrollPosition () {
    if (window.scrollY > 25) {
      document.body.classList.remove('scrolled-to-top')
    } else {
      document.body.classList.add('scrolled-to-top')
    }
  }

  render () {
    const { children, location } = this.props
    return (
      <StaticQuery query={query} render={(data) => (
        <div className="content">
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' },
            ]}
          >
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-123748252-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-123748252-1');
            `}</script>
            <link href="https://fonts.googleapis.com/css?family=Heebo:300,400,500" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Pontano+Sans" rel="stylesheet" />
            <link rel="icon" type="image/png" href={favicon} />
            <meta property="og:url" content="https://visualperspective.io" />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:description" content="Visual Perspective combines expertise in data science, visualization, GIS, and remote sensing." />
            <meta property="og:site_name" content="Visual Perspective" />
            <meta property="og:image" content='https://visualperspective.io/thumb.png' />
          </Helmet>
          <TopGraphics />
          <Header />
          <article key={location.pathname}>
            {children}
          </article>
          <Footer />
        </div>
      )} />
    )
  }
}

export default Layout
