import React from 'react'
import Link from 'gatsby-link'

const BlogEntry = () => (
  <nav>
    <Link to="/" activeClassName="active">Home</Link>
    <Link to="/blog" getProps={({ isPartiallyCurrent }) => (
      isPartiallyCurrent ? { className: "active" } : null
    )}>Blog</Link>
    <Link to="/contact" activeClassName="active">Contact Us</Link>
  </nav>
)

export default BlogEntry
